import * as _jqueryValidate from './../utils/jquery.validate.custom';
import * as _utils from './../utils/wappo.utils';

$(document).ready(function ()
{
	// module initialization
	InitModule();
});

function InitModule()
{
	$('#ContactForm').submit(function (e)
	{
		e.preventDefault();

		var _form = $(this);
		var _validator = _form.validate();

		if (_form.valid())
		{
			_utils.asyncSubmit(_form,

				// success
				function (data)
				{
					if (data.Type === 'success')
					{
						// removing reset password form
						$('#ContactForm').remove();
					}

					// notification
					_utils.addNotificationPanel($('[data-action="result-message"]'), data);
				},

				// error
				function (xhr, text, error)
				{
					// error
					console.log(error);
				}
			);
		}
		else
		{
			// invalid form
			_validator.focusInvalid();
		}
	});
}